<template>
  <div>
    <addBtn href="#" v-if="!create" class="flix-btn flix-btn-default" :onClick="function () { create = true }"><template v-slot:text>{{ $t('message.createNew', {name: $tc('message.organisation', 1)}) }}</template></addBtn>
    <transition name="flixFadeIn">
      <div  :key="create" v-if="create" >
      <p><i>Hinweis: Es können auch mehrere Kalender je Organisation definiert werden. Definieren Sie unterschiedliche Organisationen, falls unterschiedliche Rollen (Rechte) vergeben werden sollen.</i></p>
        <create :callback="closeOrganisation" />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    create () { return import('./create.vue') }
  },
  props: {},
  data () {
    return {
      create: true
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    closeOrganisation () {
      this.create = false
      this.$store.commit('clearOrganisations')
      this.$store.dispatch('getOrganisations', function () { })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
